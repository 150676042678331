import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authApi, getUserData } from "../../services/auth";
import { IAuthState, IGetUserDataResponse, IUpdateCurrentUserDataResponse } from "../../types/auth";
import { IUser } from "../../types/users";

const emptyUser: IUser = {
    id: "",
    info: {
        email: "",
        name: "",
        phone: "",
    },
    createdAt: "",
    enabled: false,
    role: {
        _id: "",
        info: {
            id: "user",
            name: "",
            deletable: false,
        },
        permissions: {
            "users:people": [],
            "users:roles": [],
            "clients:people": [],
            "clients:documents": [],
            "clients:notifications": [],
            "documents:assignment": [],
            "documents:tags": [],
            dashboard: [],
            questionnaires: [],
            transactions: [],
        },
    },
    regions: [],
};

const initialState: IAuthState = {
    user: emptyUser,
    authData: undefined,
    isAuthInProgress: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signOut: () => initialState,
        setShouldChangePassword: (state, action: PayloadAction<boolean>) => {
            state.authData!.shouldChangePassword = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(authApi.endpoints.getUserData.matchFulfilled, (state, action: PayloadAction<IGetUserDataResponse>) => {
                const {
                    _id,
                    shouldChangePassword,
                    ...rest
                } = action.payload.user;

                state.authData = { _id, shouldChangePassword };
                state.user = rest;
            })
            .addMatcher(authApi.endpoints.updateCurrentUser.matchFulfilled, (state, action: PayloadAction<IUpdateCurrentUserDataResponse>) => {
                const {
                    _id,
                    // eslint-disable-next-line no-unused-vars
                    shouldChangePassword,
                    ...rest
                } = action.payload.user;

                state.user = rest;
            });
    },
});

export const getCurrentUser = createAsyncThunk(
    "auth/getCurrentUser",
    async (_, { dispatch }) => {
        return dispatch(getUserData.initiate(undefined, {
            subscribe: false,
            forceRefetch: false,
        }));
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async (token: string, { dispatch }) => {
        localStorage.setItem("token", JSON.stringify(`Basic ${token}`));
        dispatch(getCurrentUser());
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    (_, { dispatch }) => {
        localStorage.removeItem("token");
        dispatch(authSlice.actions.signOut());
    }
);

export default authSlice.reducer;
