import { api } from "./connection/api";
import {
    IInitiateDocumentScrapingResponse,
    IConfirmPlanForDocumentByIdBody,
    IConfirmPlanForDocumentByIdResponse,
    IDeleteClientDocumentByIdBody,
    IDeleteClientDocumentByIdResponse,
    IGetClientDocumentByIdQuery,
    IGetClientDocumentByIdResponse,
    IGetDocumentsQuery,
    IGetDocumentsResponse,
    IGetSelectedDocumentsForCsvBody,
    IGetSelectedDocumentsForCsvResponse,
    IInitiateDocumentScrapingBody,
    ISetDocumentsAgentBody,
    ISetDocumentsAgentResponse,
    IUpdateDocumentByIdBody,
    IUpdateDocumentByIdResponse,
    IUpdateDocumentStatusByIdBody,
    IUpdateDocumentStatusByIdResponse,
    IUpdateSubmissionDetailsBody,
    IUpdateSubmissionDetailsResponse,
    IUpdateDocumentRefundBody,
    IUpdateDocumentRefundResponse,
    IDeleteDocumentReceiptRequestBody,
    IDeleteDocumentReceiptResponse,
    IDeleteDocumentFileRequestBody,
    IUpdateDocumentsStatusesResponse,
    IUpdateDocumentsStatusesBody,
    IUpdateDocumentSubStatusByIdResponse, IUpdateDocumentSubStatusByIdBody,
} from "../types/documents";
import { getDocumentsQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "documents";

export const documentsApi = api.injectEndpoints({
    endpoints: build => ({
        getDocuments: build.query<IGetDocumentsResponse, IGetDocumentsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getDocumentsQueryString(query)}`,
            }),
            providesTags: ["Documents"],
        }),
        setDocumentsAgent: build.mutation<ISetDocumentsAgentResponse, ISetDocumentsAgentBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-manager`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Users"],
        }),
        getSelectedDocumentsForCsv: build.mutation<IGetSelectedDocumentsForCsvResponse, IGetSelectedDocumentsForCsvBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/export`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents"],
        }),
        getDocumentById: build.query<IGetClientDocumentByIdResponse, IGetClientDocumentByIdQuery>({
            query: ({ documentId, ...query }) => ({
                url: `${ENDPOINT_PREFIX}/${documentId}?${getDocumentsQueryString(query)}`,
            }),
            providesTags: ["Document"],
        }),
        updateDocumentStatusById: build.mutation<IUpdateDocumentStatusByIdResponse, IUpdateDocumentStatusByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/status`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Document", "ClientLogs", "Users"],
        }),
        updateDocumentSubStatusById: build.mutation<IUpdateDocumentSubStatusByIdResponse, IUpdateDocumentSubStatusByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/sub-status`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Document", "ClientLogs"],
        }),
        updateDocumentsStatuses: build.mutation<IUpdateDocumentsStatusesResponse, IUpdateDocumentsStatusesBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-statuses`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Document"],
        }),
        updateSubmissionDetails: build.mutation<IUpdateSubmissionDetailsResponse, IUpdateSubmissionDetailsBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/submission-details`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["ClientDocuments", "Document", "ClientLogs"],
        }),
        updateDocumentById: build.mutation<IUpdateDocumentByIdResponse, IUpdateDocumentByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Document", "ClientLogs"],
        }),
        confirmPlanForDocument: build.mutation<IConfirmPlanForDocumentByIdResponse, IConfirmPlanForDocumentByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/confirm`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Documents", "Document"],
        }),
        initiateDocumentScraping: build.mutation<IInitiateDocumentScrapingResponse, IInitiateDocumentScrapingBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/scrape`,
                method: "PATCH",
            }),
            invalidatesTags: [],
        }),
        updateDocumentRefund: build.mutation<IUpdateDocumentRefundResponse, IUpdateDocumentRefundBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}/refund`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Document"],
        }),
        deleteDocumentReceipt: build.mutation<IDeleteDocumentReceiptResponse, IDeleteDocumentReceiptRequestBody>({
            query(data) {
                return {
                    url: `${ENDPOINT_PREFIX}/${data.documentId}/receipts`,
                    method: "DELETE",
                    body: data.data,
                };
            },
            invalidatesTags: ["Document"],
        }),
        deleteDocumentFile: build.mutation<IDeleteDocumentReceiptResponse, IDeleteDocumentFileRequestBody>({
            query(data) {
                return {
                    url: `${ENDPOINT_PREFIX}/${data.documentId}/files`,
                    method: "DELETE",
                    body: data.data,
                };
            },
            invalidatesTags: ["Document"],
        }),
        deleteDocumentById: build.mutation<IDeleteClientDocumentByIdResponse, IDeleteClientDocumentByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.documentId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Documents"],
        }),
    }),

});

export const {
    useGetDocumentsQuery,
    useSetDocumentsAgentMutation,
    useGetSelectedDocumentsForCsvMutation,
    useDeleteDocumentByIdMutation,
    useGetDocumentByIdQuery,
    useUpdateDocumentsStatusesMutation,
    useUpdateDocumentStatusByIdMutation,
    useUpdateDocumentSubStatusByIdMutation,
    useUpdateDocumentByIdMutation,
    useDeleteDocumentReceiptMutation,
    useDeleteDocumentFileMutation,
    useConfirmPlanForDocumentMutation,
    useInitiateDocumentScrapingMutation,
    useUpdateDocumentRefundMutation,
    useUpdateSubmissionDetailsMutation,
} = documentsApi;
