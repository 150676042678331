export const getAccessToken = () => {
    try {
        const token = localStorage.getItem("token");
        return `${JSON.parse(token || "")}`;
    } catch (e) {
        return undefined;
    }
};

export const getPassword = ({
    length = 12,
}: {
    length: number;
}) => {
    // Define the character set to use in the password
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!";

    // Start with an empty password
    let password = "";

    // Add characters to the password until it reaches the desired length
    while (password.length < length) {
        // Choose a random character from the character set
        const randomIndex = Math.floor(Math.random() * chars.length);
        const randomChar = chars.charAt(randomIndex);

        // Add the new character to the password
        password += randomChar;
    }

    // Return the generated password
    return password;
};
