import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortingState } from "@tanstack/react-table";

import { IClient } from "../../types/clients";
import { IPagination } from "../../types/common";

const initialState: {
    filters: {
        globalFilter: string;
        sorting: SortingState;
    },
    clients: IClient[],
    pagination: IPagination
} = {
    filters: {
        globalFilter: "",
        sorting: [],
    },
    clients: [],
    pagination: {
        totalPages: 0,
        totalDocs: 0,
    },
};

export const clientsSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        setGlobalFilter: (state, action: PayloadAction<string>) => {
            state.filters.globalFilter = action.payload;
        },
        setSorting: (state, action: PayloadAction<SortingState>) => {
            state.filters.sorting = action.payload;
        },
    },
});

export default clientsSlice.reducer;
