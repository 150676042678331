export function convertArrayOfObjectsToDict<T extends readonly string[]>(keys: T, objects: ReadonlyArray<any>): Record<T[number], any> {
    const obj: Record<string, typeof keys[number]> = {};

    for (const key of keys) {
        obj[key] = objects.find(o => o.id === key);
    }

    return obj;
}

export function convertGroupedArraysOfObjectsToDict<T extends readonly string[]>(keys: T, objects: Record<string, any[]>): Record<T[number], any> {
    const obj: Record<string, any> = {};

    for (const key of keys) {
        for (const group in objects) {
            const found = objects[group].find(o => o.id === key);
            if (found) {
                obj[key] = found;
                break;
            }
        }
    }

    return obj;
}

export function deepEqual(object1: any, object2: any) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);

        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

export function isObject(object: any) {
    return object != null && typeof object === "object";
}
