import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";

import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "./redux/store";

const container = document.getElementById("root")!;
const root = createRoot(container);
const App = lazy(() => import("./App"));

root.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#2EBB49",
                    fontSizeLG: 15,
                },
            }}
        >
            <Provider store={store}>
                <BrowserRouter basename={"/ie"}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <App/>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>
);
