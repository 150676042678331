import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import auth from "./reducers/auth";
import clients from "./reducers/clients";
import documents from "./reducers/documents";
import users from "./reducers/users";
import { api } from "../services/connection/api";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth,
        users,
        documents,
        clients,
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(api.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
