import { api } from "./connection/api";
import {
    IEmail,
    IGetUserDataResponse,
    IResetPassword,
    ISignInRequestBody,
    IUserRequestBody,
} from "../types/auth";

const ENDPOINT_PREFIX = "auth";

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        signIn: build.mutation({
            query(data: ISignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        getUserData: build.query<IGetUserDataResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/`,
            }),
            providesTags: ["Auth"],
        }),
        updateCurrentUser: build.mutation({
            query(data: IUserRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "PATCH",
                    body: data.data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        forgotPassword: build.mutation({
            query(data: IEmail) {
                return {
                    url: `${ENDPOINT_PREFIX}/forgot-password`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        resetPassword: build.mutation({
            query(data: IResetPassword) {
                return {
                    url: `${ENDPOINT_PREFIX}/reset-password`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
    }),
});

export const {
    useSignInMutation,
    useUpdateCurrentUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetUserDataQuery,
    endpoints: {
        getUserData,
    },
} = authApi;
